import React from "react"
import RefinancingForm from "../components/BuyHomeForm/RefinancingHomeForm"
import MinimalLayout from "../components/Layouts/MinimalLayout"
function BuyHomePage() {
  return (
    <MinimalLayout>
      <RefinancingForm />
    </MinimalLayout>
  )
}

export default BuyHomePage
