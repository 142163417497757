import React, { useEffect, useState } from "react"
import "./buyHomeForm.scss"
import { useStaticQuery, graphql } from "gatsby"
import GravityFormForm from "gatsby-plugin-gravity-forms"
import RefinancingHomeBg from "../../images/Refinancing.jpeg"
import arrow from "../../images/arrow icon.svg"
import {
  BuyHomeStep1,
  BuyHomeStep2,
  RefinancingHomeStep3,
  BuyHomeStep4
} from "../../validation/validation"
function RefinancingHomeForm() {
  const data = useStaticQuery(graphql`
    query {
      wpGfForm(databaseId: { eq: 5 }) {
        ...GravityFormFields
      }
    }
  `)
  const [step, setStep] = useState(1)
  useEffect(() => {
    const inputs = document.querySelectorAll(".gravityform__field")
    const step1 = document.querySelectorAll(".gravityform__field.step-1")
    const step2 = document.querySelectorAll(".gravityform__field.step-2")
    const step3 = document.querySelectorAll(".gravityform__field.step-3")
    const step4 = document.querySelectorAll(".gravityform__field.step-4")
    const step5 = document.querySelectorAll(".gravityform__field.step-5")
    const submitBtn = document.querySelector(".gravityform__button")

    Array.from(inputs).forEach(input => {
      input.classList.remove("display")
    })
    submitBtn.classList.remove("display")
    if (step == 1) {
      Array.from(step1).forEach(step => {
        step.classList.add("display")
      })
    }
    if (step == 2) {
      Array.from(step2).forEach(step => {
        step.classList.add("display")
      })
    }
    if (step == 3) {
      Array.from(step3).forEach(step => {
        step.classList.add("display")
      })
    }
    if (step == 4) {
      Array.from(step4).forEach(step => {
        step.classList.add("display")
      })
    }
    if (step == 5) {
      Array.from(step5).forEach(step => {
        step.classList.add("display")
      })
      submitBtn.classList.add("display")
    }
  }, [step])
  const next = () => {
    // inputs element
    const step1 = document.querySelectorAll(
      ".gravityform__field.field-required.step-1 input"
    )

    const step2 = document.querySelectorAll(
      ".gravityform__field.field-required.step-2 input[type='phone'], .gravityform__field.step-2 input#input_24_1"
    )
    const step3 = document.querySelectorAll(
      ".gravityform__field.field-required.step-3 input"
    )
    const step4 = document.querySelectorAll(
        ".gravityform__field.field-required.step-4 input"
    )
    // validation element
    const validateStep1 = document.querySelectorAll(
      ".gravityform__field.step-1 .validation_message"
    )
    const validateStep2 = document.querySelector(
      ".gravityform__field.step-2 .validation_message"
    )
    const validateStep3 = document.querySelectorAll(
      ".gravityform__field.step-3 .validation_message"
    )
    const validateStep4 = document.querySelectorAll(
        ".gravityform__field.step-4 .validation_message"
    )

    step === 1 && BuyHomeStep1(Array.from(step1), validateStep1, setStep, step)
    step === 2 && BuyHomeStep2(Array.from(step2), validateStep2, setStep, step)
    step === 3 &&
      RefinancingHomeStep3(Array.from(step3), validateStep3, setStep, step)
    step === 4 && BuyHomeStep4(Array.from(step4), validateStep4, setStep, step)
  }
  const prev = () => {
    setStep(step - 1)
  }

  if (typeof document !== "undefined") {
    const contactOption = document.querySelectorAll('input[name="input_10"]');
    const textMe = document.getElementById('input_10_2');

    if (contactOption && textMe) {
      contactOption.forEach(option => {
        option.addEventListener('change', function(e) {
          console.log(e, e.target.checked, e.target.value)
          if (e.target.checked && e.target.value === 'Text me now') {
            document.getElementById('input_24_1').setAttribute('required', 'required');
            document.getElementById('input_24_1').insertAdjacentHTML('afterend', '<div aria-live="polite" class="gravityform__error_message gfield_description validation_message" id="checkbox_validation_message"></div>');
          } else {
            document.getElementById('input_24_1').removeAttribute('required')
          }
        });
      })
    }
  }

  return (
    <div className="form-section buyhome-form">
      <div
        className="header-section"
        style={{ backgroundImage: `url(${RefinancingHomeBg})` }}
      >
        <div className="inner-content">
          <h2>REFINANCING YOUR HOME?</h2>
          <p>
            Refinancing at the right time, under the right circumstances, can
            make your dream home even dreamier. At Hixon Mortgage Company, we'll
            take a big picture approach to your goals, financial circumstances,
            and existing loan to help you make informed refinancing decisions.
          </p>
        </div>
      </div>
      <div className="multi-step-form ">
        <div className="form-header">
          <h2>GET A COMPLIMENTARY RATE QUOTE</h2>
          <div className="progress-bar">
            <div
              className="bar-percentage"
              style={{ width: (step / 5) * 100 + "%" }}
            ></div>
          </div>
          <p className="progress-title">Step: {step} OF 5</p>
        </div>

        <div className={`step-wrapper step-${step}`}>
          <GravityFormForm data={data} />
        </div>
        {step < 5 ? <Next next={next} /> : <></>}
        {step >= 2 ? <Back prev={prev} /> : <></>}
      </div>
    </div>
  )
}

function Next({ next }) {
  return (
    <div className="handle-button" onClick={next}>
      <div className="next-button">Next</div>
    </div>
  )
}
function Prev({ prev }) {
  return (
    <div onClick={prev} className="handle-button prev">
      <div className="prev-button">Previous</div>
    </div>
  )
}
function Back({ prev }) {
  return (
    <div onClick={prev} className="handle-button back">
      <div className="back-button">
        <img src={arrow} alt="arrow" style={{ transform: "scaleX(-1)" }} /> Back
      </div>
    </div>
  )
}
export default RefinancingHomeForm
